<template>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-button
          style="float: right"
          type="primary"
          @click="opendialog()"
          >添加广告</el-button
        >
      </div>
      <el-dialog :title="form._id ? '更新' : '添加'" :visible.sync="dialogFormVisible"  :closeOnClickModal="false" :showClose="false">
        <el-form :model="form">
          <el-form-item label="封面" :label-width="formLabelWidth">
            <el-upload
              ref='uploadImgs'
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="uploadFile"
              :before-upload="beforeAvatarUpload"
              :on-success="clearimgfile"
            >
              <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="标题" :label-width="formLabelWidth">
            <el-input
              v-model="form.title"
              autocomplete="off"
              placeholder="请填写该广告标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="广告类型" :label-width="formLabelWidth">
            <el-select
              v-model="form.bannertype"
              placeholder="选择类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序" :label-width="formLabelWidth">
            <el-input
              v-model="form.sort"
              autocomplete="off"
              placeholder="请填写数字，数字越小，排序越前面"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否显示" :label-width="formLabelWidth">
            <el-select v-model="form.isshow" placeholder="请选择">
              <el-option
                v-for="item in showoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closedialog()">取 消</el-button>
          <el-button v-if="!form._id" type="primary" @click="createbanner(form)">添加广告</el-button>
          <el-button v-if="form._id" type="primary" @click="editbanner(form)">更新广告</el-button>
        </div>
        <el-divider></el-divider>
        <el-alert
            title="小程序首页广告尺寸《750*436》，小程序用户背景尺寸《750*322》，小程序医生背景尺寸《750*300》，综合信息屏广告《560*738》"
            type="error"
            effect="dark"
            :closable="false"
        >
        </el-alert>
      </el-dialog>
      <el-table
        :data="items"
        style="width: 100%; margin-bottom: 20px"
        row-key="_id"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="封面">
          <template slot-scope="scope">
              <el-popover placement="top-start" title="" trigger="hover">
                <img :src="scope.row.imageUrl" alt="" style="width: 150px;height: 150px">
                <img slot="reference" :src="scope.row.imageUrl" style="width: 30px;height: 30px">
              </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            {{ bannertypetext(scope.row.bannertype) }}
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="isshow" label="是否显示">
          <template slot-scope="scope">
            <el-switch
            disabled
              v-model="scope.row.isshow"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间" :formatter="formatTime"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleedit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="handlePageChange"
        @size-change="handlePageSizeChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </template>
  <script>
  import { VueEditor } from "vue2-editor";
  
  export default {
    components: { VueEditor },
    data() {
      return {
        imageUrl:'',
        editdialogFormVisible: false,
        dialogFormVisible: false,
        formLabelWidth: "120px",
        currentPage: 1, // 当前页码
        pageSize: 10, // 每页数据量
        total: 0, // 总数据量
        items: [],
        showoptions: [{
          value: true,
          label: '显示'
        }, {
          value: false,
          label: '不显示'
        }],
        recommendoptions: [{
          value: true,
          label: '是'
        }, {
          value: false,
          label: '否'
        }],
        options: [{
          value: 'index',
          label: '小程序首页'
        }, {
          value: 'userbg',
          label: '小程序用户页背景'
        }, {
          value: 'doctorbg',
          label: '小程序医生页背景'
        }, {
          value: 'other',
          label: '综合信息屏广告'
        }],
        bannertypemap:{
          index:"小程序首页",
          userbg:"小程序用户页背景",
          doctorbg:"小程序医生页背景",
          other:"综合信息屏广告",
        },
        form: {
          _id: "",
          imageUrl: "",
          title: "",
          bannertype: "",
          sort:0,
          isshow:false
        },
      };
    },
    methods: {
      bannertypetext(text) {
        return this.bannertypemap[text] || "未知状态"
      },
      clearimgfile(){
        this.$refs.uploadImgs.clearFiles();
      },
      closedialog(){
        this.dialogFormVisible = false
        this.form = {
          _id: "",
          imageUrl: "",
          title: "",
          bannertype: "",
          sort:0,
          isshow:false
        }
      },
      opendialog(){
        this.dialogFormVisible = true
        this.form = {
          _id: "",
          imageUrl: "",
          title: "",
          bannertype: "",
          sort:0,
          isshow:false
        }
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.loadItems();
      },
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.loadItems();
      },
      handleedit(data){
        this.dialogFormVisible = true;
        this.form = data
      },
      handleDelete(data){
          this.$api.banner.deletebanner(data).then(res =>{
            this.$message({
              message:res.data.msg,
              type: 'success'
            });
            this.loadItems()
          })
      },
      editbanner(data){
        this.$api.banner.editbanner(data).then(res=>{
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.dialogFormVisible = false;
          this.loadItems();
        }).catch(err=>{
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
          this.dialogFormedit = false;
        })
      },
      uploadFile(param) {
        const formData = new FormData();
        formData.append("bannercover", param.file);
        console.log(param.file);
        this.$api.banner
          .bannercover(formData)
          .then((res) => {
            console.log(res.data.data);
            this.form.imageUrl = res.data.data;
            console.log(this.form);
          })
          .catch((res) => {
            this.$message.error("上传失败，请稍后重试");
          });
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;
  
        if (!isJPG) {
          this.$message.error("上传图片只能是 JPG 格式!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 2MB!");
        }
        return isJPG && isLt2M;
      },
      createbanner(data) {
        this.$api.banner
          .createbanner(data)
          .then((res) => {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.loadItems()
          })
          .catch((err) => {
            err;
          });
      },
      loadItems(){
        const data = {
          currentPage: this.currentPage,
          pageSize:this.pageSize
        }
        this.$api.banner.getpagination(data).then(res =>{
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        })
      },
      formatTime(row, column) {
        let data = row[column.property];
        let dtime = new Date(data);
        const year = dtime.getFullYear();
        let month = dtime.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let day = dtime.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        let hour = dtime.getHours();
        if (hour < 10) {
          hour = "0" + hour;
        }
        let minute = dtime.getMinutes();
        if (minute < 10) {
          minute = "0" + minute;
        }
        let second = dtime.getSeconds();
        if (second < 10) {
          second = "0" + second;
        }
        return year + "-" + month + "-" + day;
      },
    },
    created() {
      this.loadItems()
    },
  };
  </script>
  <style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .el-row :last-child {
    margin-bottom: 0;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .logo {
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }
  .text {
    font-size: 14px;
  }
  
  .item {
    margin-bottom: 18px;
  }
  
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  
  .box-card {
    width: 100%;
  }
  </style>
  